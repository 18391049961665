import { MyAccountPersonalInformation } from 'models/myAccount';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

const getMyAccountPersonalInformation = (state: AppState) => state.myAccountPersonalInformation;
type UseMyAccountPersonalInformationResponse = {
    personalInformation: Nullable<MyAccountPersonalInformation>;    
    isLoading: boolean;    
};

export const useMyAccountPersonalInformation = (): UseMyAccountPersonalInformationResponse => {
    const myAccountPersonalInformation = useSelector(getMyAccountPersonalInformation);
    return {
        ...myAccountPersonalInformation,
    };
};