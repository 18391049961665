import { ServiceBoardServiceModel } from 'models/serviceBoardService';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from 'store/store';

type UseRecommendedServicesResponse = {
    recommendedServices: ServiceBoardServiceModel[];
    isLoading: boolean;
};

export const useRecommendedServices = (limit: number): UseRecommendedServicesResponse => {
    const recommendedServicesState = useSelector((state: AppState) => state.recommendations, shallowEqual);
    const recommendedServices = recommendedServicesState.recommendedServices || [];
    return {
        recommendedServices: recommendedServices.slice(0, limit),
        isLoading: recommendedServicesState.isLoading,
    };
};
