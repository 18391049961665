import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceLogo } from 'components/common/logo/ServiceLogo';
import { RequestAccessIfMissing } from 'components/common/request-access/RequestAccessIfMissing';
import { ServiceLink } from 'components/common/service-link/ServiceLink';
import { ServiceTrackingInfoProvider } from 'components/common/tracking/serviceTrackingContext';
import { useLocalizedObject } from 'components/hooks/translation/useLocalizedObject';
import { ServiceBoardServiceModel } from 'models/serviceBoardService';
import { DEFAULT_CATEGORY } from 'models/constants';
import { TopicTracked, ServiceTracked } from 'models/tracking';
import { BookmarkStarButton } from '../../components/bookmark/BookmarkButtons';
import { RecommendationDetailsLink } from './RecommendationDetailsLink';
import { BookmarkOrigin } from 'models/bookmark';

type RecommendationCardProps = {
    service: ServiceBoardServiceModel;
};

export const RecommendationCard: React.FunctionComponent<RecommendationCardProps> = ({
    service,
}) => {
    const { t: translate } = useTranslation('service');
    const localize = useLocalizedObject();
    const serviceTracked: ServiceTracked = {
        topic: TopicTracked.RecommendedServices,
        service: service,
        origin: BookmarkOrigin.SgmService,
    };
    return (
        <ServiceTrackingInfoProvider serviceTracked={serviceTracked}>
            <div className="col-md-6 col-lg-4 p-1 p-lg-2">
                <ServiceLink
                    serviceKey={service.ServiceKey}
                    url={service.Url}
                    className="card card-raising p-0"
                >
                    <div className="d-flex align-items-center border-bottom border-secondary border-opacity-10 p-3">
                        <div className="d-flex flex-grow-1 align-items-center overflow-hidden">
                            <ServiceLogo initials={service.Initials} size={48} />
                            <div className="flex-shrink-1 px-3 overflow-hidden">
                                <p className="fw-semibold text-truncate mb-0">{service.Title}</p>
                                <label className="text-secondary fw-normal mb-0">
                                    {service.Category?.Key ? translate(service.Category?.Key) : DEFAULT_CATEGORY}
                                </label>
                            </div>
                        </div>
                        <div className="flex-shrink-1">
                            <BookmarkStarButton
                                referenceId={service.ServiceKey}
                                origin={BookmarkOrigin.SgmService} />
                            <RequestAccessIfMissing service={service} useIcon={true} />
                        </div>
                    </div>
                    <div
                        className="fw-normal position-relative text-secondary m-3 overflow-hidden"
                        style={{
                            height: '60px',
                        }}
                    >
                        {service.Descriptions && localize(service.Descriptions)}
                        <RecommendationDetailsLink serviceKey={service.ServiceKey} />
                    </div>
                </ServiceLink>
            </div>
        </ServiceTrackingInfoProvider>
    );
};
