import { SgConnectEnvironment } from 'services/SgConnect/SgConnectEnvironment';

export interface Configuration {
    sgConnect: SGConnectConfiguration;
    sgConnectEnvironment: SgConnectEnvironment,
    piwik: PiwikConfiguration;
    environment: string;
    serviceBoardUrl: string;
    infoUrl: string;
    insightUrl: string;
    insightPublicUrl: string;
    seeAllInIc: string;
    returnUrlDomainWhiteList: string[];
    sharedCdnUrl: string;
    nonSgmLinkReferentialUrl: string;
    logoutUrl: string;
    changePasswordUrl: string;
    manage3SKeyUrl: string;
    resetSecondFactorUrl: string;
    setupSecondFactorUrl: string;
    insightSubscriptionUrl: string;
    sharinboxPersoInfoUrl: string;
    personalInformationHelpSgGroup: string;
    personalInformationHelpClient: string;
    sgLinkDocumentation: string;
    baseApiUrls: {
        sgmSearch: string;
        notification: string;
    };
    authorizationEndpoint: string
    clientId: string;
    scope: string;
}

export interface PiwikConfiguration {
    url: string;
    siteId: string;
}

export interface SGConnectConfiguration {
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    level: string;
    redirect_uri: string;
}

export enum Environment {
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Production = 'Production',
    Test = 'Test',
}