import { SortField, sortServicesBy } from 'components/pages/myServicesPage/layout/allservices/sort-service-list';
import { buildServiceBoardServiceKey, getServiceByKey, hasServiceKey } from 'core/services/serviceBoardService/serviceBoardService';
import { caseInsensitiveEquals } from 'core/utils/service-matchers';
import { AccessType } from 'models/accessType';
import { ServiceBoardServiceModel, ServiceBoardCategoryModel, NonSgmServiceBoardServiceModel, IServiceBoardServiceState } from 'models/serviceBoardService';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { groupBy } from 'lodash-es';
import { useExternalService } from './useExternalService';

const getServiceBoardServiceState = (state: AppState): IServiceBoardServiceState => state.boardServices;

const getServices = (serviceBoardServiceState: IServiceBoardServiceState, accessType: AccessType, language: string): [ServiceBoardServiceModel[], NonSgmServiceBoardServiceModel[], boolean] => {
    const serviceKey = buildServiceBoardServiceKey(accessType, language);
    const dataService = serviceBoardServiceState.data[serviceKey];
    return [dataService?.services || [], dataService?.nonSgmServices || [], dataService?.loading || false];
};

export const useHasAccessServices = () => {
    const serviceBoardServiceState = useSelector(getServiceBoardServiceState);

    const hasAccessTo = (serviceKey: string): boolean => serviceBoardServiceState.accessServices.some((x) => caseInsensitiveEquals(x, serviceKey));
    const hasAccessToServicePage = (servicePageId: string): boolean => serviceBoardServiceState.accessPages.some((x) => caseInsensitiveEquals(x, servicePageId));

    return {
        hasAccessTo,
        hasAccessToServicePage,
    };
};

export const useBoardServices = (accessType: AccessType): {
    services: ServiceBoardServiceModel[], nonSgmServices: NonSgmServiceBoardServiceModel[], loading: boolean
} => {
    const { i18n: { language } } = useTranslation();
    const serviceBoardServiceState = useSelector(getServiceBoardServiceState);
    const [services, nonSgmServices, loading] = getServices(serviceBoardServiceState, accessType, language);
    return {
        services,
        nonSgmServices,
        loading,
    };
};

export const useBoardServiceForDetail = (): {
    loading: boolean,
    getSgmServiceByKey: (key: string) => ServiceBoardServiceModel | null,
    hasSgmServiceKey: (key: Nullable<string>) => boolean
} => {
    const { services, loading } = useBoardServices(AccessType.All);

    return {
        loading,
        getSgmServiceByKey: (key) => getServiceByKey(services, key),
        hasSgmServiceKey: (key) => hasServiceKey(services, key),
    };
};

interface IUseCatalogServices {
    visibleServices: ServiceBoardServiceModel[],
    visibleCategories: ServiceBoardCategoryModel[],
    loading: boolean,
    getSgmServiceByKey: (key: string) => Nullable<ServiceBoardServiceModel>,
    getNonSgmServiceById: (id: string) => Nullable<NonSgmServiceBoardServiceModel>,
}
export const useCatalogServices = (): IUseCatalogServices => {
    const { services, nonSgmServices, loading } = useBoardServices(AccessType.BothForCatalog);
    const { showOnlyExternalService } = useExternalService();

    const visibleServices = services?.filter(x => !showOnlyExternalService || (!x.IsInternal && x.SgmCatalog?.toLocaleLowerCase() === 'all')) || [];

    const servicesByCategory = groupBy(visibleServices, x => x.Category?.Key);
    const visibleCategories = Object.keys(servicesByCategory).filter(x => x).map(x => servicesByCategory[x][0].Category as ServiceBoardCategoryModel);

    return {
        visibleServices: sortServicesBy(visibleServices, SortField.Title),
        visibleCategories,
        loading,
        getSgmServiceByKey: (key) => getServiceByKey(services, key),
        getNonSgmServiceById: (id) => nonSgmServices?.find(nss => nss.Id === id),
    };
};
