import { Bookmark } from 'models/bookmark';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

const getBookmarks = (state: AppState): Nullable<Bookmark[]> => {
    return state.bookmark.items;
};

type UseBookmarkResponse = {
    bookmarks: Bookmark[];
    getBookmarkByReferenceId: (referenceId: Nullable<string>) => Bookmark | undefined;
};

export const useBookmark = (): UseBookmarkResponse => {
    const bookmarks = useSelector(getBookmarks) || [];
    const getBookmarkByReferenceId = (referenceId: Nullable<string>) => {
        const bookmark = bookmarks.find(b => !!referenceId && referenceId.toLocaleLowerCase() === b.ReferenceId?.toLocaleLowerCase());

        return bookmark;
    };
    return {
        bookmarks,
        getBookmarkByReferenceId,
    };
};