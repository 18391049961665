import { AllMyServiceModel } from 'models/allMyServices';
import { useEssentialServices } from './useEssentialServices';
import { useBookmark } from '../bookmark/useBookmark';
import { Bookmark, BookmarkOrigin } from 'models/bookmark';
import { NON_SGM_RELATED_TO_PLATFORM_SGM } from 'models/constants';

type UseAllMyServicesResponse = {
    allMyservices: AllMyServiceModel[];
    sgmServices: AllMyServiceModel[];
    nonSgmServicebookmarked: AllMyServiceModel[];
    hasNonSgmServicebookmarked: boolean;
    showAllMyServicesTab: boolean;
};

export const useAllMyServices = (): UseAllMyServicesResponse => {
    const { allEssentialServices, showSeeAllServices } = useEssentialServices();
    const { bookmarks } = useBookmark();

    const nonSgmServicebookmarked: AllMyServiceModel[] = buildNonSgmServicebookmarked(bookmarks, nonSgmServicebookmarkedPredicate);
    const nonSgmServiceRelatedToSgmBookmarked: AllMyServiceModel[] = buildNonSgmServicebookmarked(bookmarks, nonSgmServiceRelatedToSgmBookmarkedPredicate, NON_SGM_RELATED_TO_PLATFORM_SGM);
    const allMyservices = [...allEssentialServices, ...nonSgmServiceRelatedToSgmBookmarked, ...nonSgmServicebookmarked];
    const sgmServices = [...allEssentialServices, ...nonSgmServiceRelatedToSgmBookmarked];
    const hasNonSgmServicebookmarked = nonSgmServicebookmarked.length > 0;
    const showAllMyServicesTab = showSeeAllServices || hasNonSgmServicebookmarked;
    return {
        allMyservices,
        sgmServices: sgmServices,
        nonSgmServicebookmarked,
        hasNonSgmServicebookmarked,
        showAllMyServicesTab,
    };
};

const nonSgmServicebookmarkedPredicate = (b: Bookmark): boolean => !!b.ReferenceId && b.Origin === BookmarkOrigin.NonSgmService && (!b.RelationType || b.RelationType !== 'RelatedToSgmPlatform');
const nonSgmServiceRelatedToSgmBookmarkedPredicate = (b: Bookmark): boolean => !!b.ReferenceId && b.Origin === BookmarkOrigin.NonSgmService && (!!b.RelationType && b.RelationType === 'RelatedToSgmPlatform');
const buildNonSgmServicebookmarked = (bookmarks: Bookmark[], filterPredicate: (b: Bookmark) => boolean, relatedTo?: string): AllMyServiceModel[] => {
    return bookmarks.filter(filterPredicate).map(b => {
        return {
            Id: b.ReferenceId,
            Title: b.Title,
            BookmarkId: b.Id,
            IsBookmarked: true,
            Url: b.PageUrl,
            Initials: b.Initials,
            IsSgmService: false,
            RelatedTo: relatedTo,
        };
    });
};