import { combineReducers } from 'redux';
import {
    LearnMoreItemFetchSuccessActionType,
    LearnMoreItemFetchRequestActionType,
    LearnMoreItemFetchFailureActionType,
} from '../actions/learnMoreItemAction';
import { ActionType } from 'store/actions/ActionType';
import { LearnMoreItem } from 'models/learnMoreItem';

const items = (state: Nullable<LearnMoreItem[]> = null, action: ActionType) => {
    if (action.type === LearnMoreItemFetchSuccessActionType) {
        return action.payload;
    }
    return state;
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case LearnMoreItemFetchRequestActionType: {
            return true;
        }
        case LearnMoreItemFetchFailureActionType:
        case LearnMoreItemFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    items,
    isLoading,
});
