import { caseInsensitiveEquals } from 'core/utils/service-matchers';
import { MyHighlightLayoutModel, HighlightLayoutPageName } from 'models/highlightLayoutModels';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

const getLayouts = (state: AppState): Nullable<MyHighlightLayoutModel[]> => state.highlightLayout.layouts;

type UseHighlightLayoutResponse = {
    getLayoutsByPage: (page: HighlightLayoutPageName) => MyHighlightLayoutModel[],
    getLayoutsByCategoryKey: (categoryKey: string, page: HighlightLayoutPageName) => MyHighlightLayoutModel[],
};

export const useHighlightLayout = (): UseHighlightLayoutResponse => {
    const layouts = useSelector(getLayouts) || [];
    const getLayoutsByPage = (page: HighlightLayoutPageName) => layouts?.filter(x => caseInsensitiveEquals(page, x.page)) || [];
    const getLayoutsByCategoryKey = (categoryKey: string, page: HighlightLayoutPageName) =>
        layouts?.filter(x => caseInsensitiveEquals(categoryKey, x.categoryKey) && caseInsensitiveEquals(page, x.page)) || [];

    return {
        getLayoutsByPage,
        getLayoutsByCategoryKey,
    };
};
