import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CATEGORY } from 'models/constants';

type ServiceDescriptionTitleProps = {
    name: Nullable<string>;
    categoryKey: Nullable<string>;
    isInternal?: boolean;
};

export const ServiceDescriptionTitle: React.FunctionComponent<ServiceDescriptionTitleProps> = ({
    name,
    categoryKey,
    isInternal,
}) => {
    const { t: translateService } = useTranslation('service');
    return (
        <div>
            <h3
                className="modal-title h3"
                id="serviceDescriptionTitle"
            >
                {name}
            </h3>
            <div className="d-flex align-items-center gap-2">
                <span className="text-large text-secondary mb-0">
                    {translateService(`${categoryKey}`) || DEFAULT_CATEGORY}
                </span>
                {isInternal && (
                    <span className="badge badge-discreet-danger badge-lg rounded-pill">
                        {translateService('service:internal')}
                    </span>
                )}
            </div>
        </div>
    );
};
