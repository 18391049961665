import { ServiceBoardServiceModel } from 'models/serviceBoardService';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { take } from 'lodash-es';

const ESSENTIALS_COUNT = 7;

const getEssentialServices = (state: AppState): Nullable<ServiceBoardServiceModel[]> => {
    return state.essentialService.items;
};

type UseEssentialServicesResponse = {
    allEssentialServices: ServiceBoardServiceModel[];
    essentialServices: ServiceBoardServiceModel[];
    showSeeAllServices: boolean;    
};

export const useEssentialServices = (): UseEssentialServicesResponse => {
    const essentialServices = useSelector(getEssentialServices) || [];    
    return {
        allEssentialServices: essentialServices,
        essentialServices: take(essentialServices, ESSENTIALS_COUNT),
        showSeeAllServices: essentialServices.length > ESSENTIALS_COUNT,        
    };
};
