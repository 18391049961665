import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { NavigateAsUser, UserContact } from '../../../models/authenticatedUser';
import { getAuthenticatedUserContacts } from 'core/services/authenticateUser/authenticatedUserService';
import { IMPERSONATING_USER } from '../../../models/constants';
import { getSessionItem, setSessionItem, removeSessionItem } from '../../../core/services/sessionStorageService';
import { reloadPage } from '../../../core/services/redirectService';

const getUserContacts = (app: AppState): Nullable<UserContact[]> =>
    app.authenticatedUserContacts.userContacts;

type UseNavigateAs = {
    showNavigateAs: Nullable<boolean>;
    navigateAsUser: Nullable<NavigateAsUser>;
    navigateAsUserContacts: Nullable<NavigateAsUser[]>;
    changeNavigateAsUserCallback: (event: Event) => void;
    stopNavigateAsCallback: (event: Event) => void;
};

export const useNavigateAs = (): UseNavigateAs => {
    const dispatch = useDispatch();
    let navigateAsUser: Nullable<NavigateAsUser> = null;
    let showNavigateAs: Nullable<boolean> = null;
    const userContacts = useSelector(getUserContacts);
    const navigateAsUserContacts = userContacts ? userContacts.map(c => {
        const user: NavigateAsUser = {
            name: `${c.LastName} ${c.FirstName}`,
            company: c.Company ? c.Company : '',
            icId: c.IcId ? c.IcId : '',
        };

        return user;
    }) : [];
    
    const hasContacts = navigateAsUserContacts && navigateAsUserContacts.length > 0;    
    if (hasContacts) {
        navigateAsUser = getSessionItem<NavigateAsUser>(IMPERSONATING_USER);
        showNavigateAs = true;
    }

    useEffect(() => {
        dispatch<any>(getAuthenticatedUserContacts());
    }, [dispatch]);

    const changeNavigateAsUserCallback = useCallback(
        (evt: Event) => {
            const user = (evt as any).detail.user as NavigateAsUser;
            const impersonatingUser = getSessionItem<NavigateAsUser>(IMPERSONATING_USER);
            if (!impersonatingUser || user.icId !== impersonatingUser.icId) {
                setSessionItem<NavigateAsUser>(IMPERSONATING_USER, user);
                reloadPage();
            }
        }, []);

    const stopNavigateAsCallback = useCallback(
        () => {        
            removeSessionItem(IMPERSONATING_USER);
            reloadPage();
        }, []);
    
    return { showNavigateAs, navigateAsUser, navigateAsUserContacts, changeNavigateAsUserCallback, stopNavigateAsCallback };
};
