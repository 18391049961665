import 'react-app-polyfill/ie11';
import React, { FC } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './components/App';
import { ServiceBoardClient } from './core/externalClient/serviceBoardClient';
import { getConfiguration } from './core/services/common/configuration/configurationLoader';
import { TechnicalLogger } from './core/services/common/logging/logger';
import { loadSGConnect } from './core/services/common/sgConnect';
import { configureStore } from './store/store';
import { configureI18n } from 'config/i18n';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import { MatomoWidget } from 'components/common/matomo/MatomoWidget';
import { messages, locales } from 'virtual-vite-i18n-resources';
import classNames from 'classnames';

import './polyfill';
import 'pixeden-stroke-7-icon';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

const startup = async () => {
    const container = document.getElementById('root');
    if (!container) {
        console.log('Can\'t find root container. App can\'t start');
        return;
    }

    loadSGConnect(getConfiguration().sgConnect);

    const i18n = await configureI18n(messages, locales);

    const store = configureStore({
        serviceBoardClient: new ServiceBoardClient(),
        logger: new TechnicalLogger(),
        translate: i18n.t,
    }, []);

    const root = createRoot(container);
    root.render(<RootApp store={store} />);
};

interface IRootAppProps {
    store: ReturnType<typeof configureStore>
}

const RootApp: FC<IRootAppProps> = ({ store }) => {
    return <Provider store={store}>
        <Router>
            <MatomoWidget />
            <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                autoClose={5000}
                hideProgressBar={false}
                closeButton={false}
                icon={false}
                newestOnTop
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={3}
                role="alert"
                toastClassName={context => classNames(
                    'notification fade show position-relative',
                    {
                        'notification-danger': context?.type === 'error',
                        'notification-info': context?.type === 'info',
                        'notification-success': context?.type === 'success',
                        'notification-warning': context?.type === 'warning',
                        'notification-primary': context?.type === 'default',
                    },
                )}
                progressClassName={context => classNames(
                    context?.defaultClassName,
                    {
                        'bg-danger': context?.type === 'error',
                        'bg-info': context?.type === 'info',
                        'bg-success': context?.type === 'success',
                        'bg-warning': context?.type === 'warning',
                        'bg-primary': context?.type === 'default',
                    },
                )}
                progressStyle={{ height: '3px' }}
            />
            <App />
        </Router>
    </Provider>;
};

startup();
