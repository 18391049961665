import { BookmarkOrigin } from './bookmark';
import { ServiceBoardServiceModel } from './serviceBoardService';

export enum TopicTracked {
    None = 'None',
    Essentials = 'Essentials',
    AllMyServices = 'AllMyServices',
    Bookmarks = 'Bookmarks',
    Learnmore = 'Learnmore',
    RecommendedServices = 'RecommendedServices',
    QuickSearch = 'QuickSearch',
    AdvancedSearch = 'AdvancedSearch',
    ServiceDetail = 'ServiceDetail',
    Help = 'Help',
    Visit = 'Visit',
    ServiceboardMenu = 'ServiceboardMenu',
    CatalogForYou = 'CatalogForYou',
    CatalogBrowse = 'CatalogBrowse',
    MyAccount = 'MyAccount',
}

export enum FeatureTracked {
    OpenService = 'OpenService',
    AddBookmark = 'AddBookmark',
    RemoveBookmark = 'RemoveBookmark',
    ViewServiceDetails = 'ViewServiceDetails',
    OpenAllMyServices = 'OpenAllMyServices',
    OpenSGMarketsTab = 'OpenSGMarketsTab',
    OpenSGTab = 'OpenSGTab',
    OpenStory = 'OpenStory',
    OpenRequestAccess = 'OpenRequestAccess',
    OpenQuickSearch = 'OpenQuickSearch',
    OpenAdvancedSearch = 'OpenAdvancedSearch',
    OpenSGMResults = 'OpenSGMResults',
    OpenSGAllResults = 'OpenSGAllResults',
    OpenPersonAllResults = 'OpenPersonAllResults',
    OpenCompanyAllResults = 'OpenCompanyAllResults',
    OpenIcSearch = 'OpenIcSearch',
    OpenForm = 'OpenForm',
    OpenCompany = 'OpenCompany',
    OpenPerson = 'OpenPerson',
    FilterSGMResults = 'FilterSGMResults',
    FilterSGResults = 'FilterSGResults',
    FilterPersonResults = 'FilterPersonResults',
    FilterCompanyResults = 'FilterCompanyResults',
    FilterSGResultsByCategory = 'FilterSGResultsByCategory',
    FilterSGResultsBySubCategory = 'FilterSGResultsBySubCategory',
    Share = 'Share',
    OpenHelpCenter = 'OpenHelpCenter',
    Visit = 'Visit',
    Visitor = 'Visitor',
    OpenSuggestedCategory = 'OpenSuggestedCategory',
    OpenMyServices = 'OpenMyServices',
    OpenCatalog = 'OpenCatalog',
    OpenCatalogForYou = 'OpenCatalogForYou',
    CatalogBrowse = 'CatalogBrowse',
    OpenCatalogBrowse = 'OpenCatalogBrowse',
    BrowseCategory = 'BrowseCategory',
    FilterBySubCategory = 'FilterBySubCategory',
    ViewAllMarketsSubCategory = 'ViewAllMarketsSubCategory',
    MyAccountTab = 'MyAccountTab',
    ViewAsClient = 'ViewAsClient',
    OpenImageBanner = 'OpenImageBanner',
    OpenInsightPublicCard = 'OpenInsightPublicCard',
}

export type TopicWithActionTracked = {
    [key: string]: FeatureTracked[];
};

export type ServiceTracked = {
    topic: TopicTracked;
    service?: ServiceBoardServiceModel;
    searchTerm?: string;
    origin: Nullable<BookmarkOrigin>;
};

export type ActionTrackedInfo = {
    topic: TopicTracked;
    parameters?: { [key: string]: unknown };
};
