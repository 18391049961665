import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { AuthenticatedUser } from 'models/authenticatedUser';
import { FeatureTracked, ServiceTracked, ActionTrackedInfo } from 'models/tracking';
import { sendTrackService, sendTrackAction, isSearchTopicTracking } from 'core/services/common/tracking/trackingService';
import { useSearchTerm } from '../search/useSearchTerm';
import { TRACKING_SEARCH_TERM_KEY } from 'models/constants';

const getAuthenticatedUser = (app: AppState): Nullable<AuthenticatedUser> =>
    app.authentification.user;

export interface UseServiceTracking {
    trackService: (featureTracked: FeatureTracked, serviceTracked: ServiceTracked) => Promise<void>;
    trackAction: (featureTracked: FeatureTracked, actionTrackedInfo: ActionTrackedInfo) => Promise<void>;
}

export const useServiceTracking = (): UseServiceTracking => {
    const authenticatedUser = useSelector(getAuthenticatedUser);
    const { i18n: { language } } = useTranslation();
    const { searchTerm } = useSearchTerm();

    const trackService = useCallback(async (featureTracked: FeatureTracked, serviceTracked: ServiceTracked) => {
        if (serviceTracked && isSearchTopicTracking(serviceTracked.topic, featureTracked)) {
            serviceTracked.searchTerm = searchTerm || '';
        }
        await sendTrackService(featureTracked, serviceTracked, authenticatedUser, language);
    }, [authenticatedUser, language, searchTerm]);

    const trackAction = useCallback(async (featureTracked: FeatureTracked, actionTrackedInfo: ActionTrackedInfo) => {
        if (actionTrackedInfo && isSearchTopicTracking(actionTrackedInfo.topic, featureTracked)) {
            const term = (actionTrackedInfo.parameters && actionTrackedInfo.parameters[TRACKING_SEARCH_TERM_KEY]) || searchTerm || '';
            actionTrackedInfo.parameters = {...actionTrackedInfo.parameters, ...{[TRACKING_SEARCH_TERM_KEY]: term}};
        }
        await sendTrackAction(featureTracked, actionTrackedInfo, authenticatedUser, language);
    }, [authenticatedUser, language, searchTerm]);

    return {
        trackService,
        trackAction,
    };
};